<template>
  <div class="staff-members">
    <lenon-list-page
      title="Staff Members"
      :columns="columns"
      :rows="staffMembers"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-search="true"
      :show-details="true"
      :show-profile-photo="true"
      enable-multi-select
      search-placeholder="Type to search"
      @onRowSelect="(items) => { selectedStaffMembers = items }"
    >
      <template slot="right-extra-header-actions">
        <div
          v-responsive="largeScreen"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-button
            variant="outline-primary"
            class="mr-2"
            icon="MessageCircleIcon"
            label="Notify"
            tool-tip-text="Send SMS & Push Notifications"
            @onClick="showNotificationModal()"
          />
          <lenon-button
            variant="outline-primary"
            class="mr-2"
            icon="SettingsIcon"
            label="Staff Positions"
            tool-tip-text="Manage Staff Positions (CRUD)"
            @onClick="showStaffPositionModal()"
          />
          <lenon-button
            variant="outline-primary"
            icon="SettingsIcon"
            class="mr-2"
            label="Departments"
            tool-tip-text="Manage Departments (CRUD)"
            @onClick="showDepartmentModal()"
          />
          <lenon-button
            icon="SettingsIcon"
            label="Staff Members"
            tool-tip-text="Manage Staff Members (CRUD)"
            class=""
            @onClick="showStaffMemberModal()"
          />
        </div>
        <div
          v-responsive="smallScreen"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-dropdown left>
            <lenon-dropdown-item
              icon="MessageCircleIcon"
              text="Notify"
              @click="showNotificationModal()"
            />
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Staff Positions"
              @click="showStaffPositionModal()"
            />
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Departments"
              @click="showDepartmentModal()"
            />
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Staff Members"
              @click="showStaffMemberModal()"
            />
          </lenon-dropdown>
        </div>
      </template>
      <template #row-details="{ row }">
        <b-card>
          <b-row class="mb-2">
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Position : </strong><span class="font-weight-bold">{{ row.item.position }}</span>
            </b-col>
            <!--            <b-col-->
            <!--              md="4"-->
            <!--              class="mb-1"-->
            <!--            >-->
            <!--              <strong>Department : </strong><span class="font-weight-bold">{{ row.item.department }}</span>-->
            <!--            </b-col>-->
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Base Salary : </strong><span class="font-weight-bold"><b-badge variant="light-success">{{
                row.item.base_salary | currency }}</b-badge></span>
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Employment Start Date : </strong><span class="font-weight-bold">{{
                row.item.employment_start_date
              }}</span>
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Employment Type : </strong><span class="font-weight-bold"><b-badge variant="light-success">{{
                row.item.employment_type }}</b-badge></span>
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Gender : </strong><span class="font-weight-bold"><b-badge variant="light-success">{{ row.item.gender
              }}</b-badge></span>
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Dob : </strong><span class="font-weight-bold">{{ row.item.dob }}</span>
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Age : </strong><span class="font-weight-bold"><b-badge variant="light-success">{{
                row.item.age
              }}</b-badge></span>
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Address : </strong><span class="font-weight-bold">{{ row.item.address }}</span>
            </b-col>
            <b-col
              md="12"
              class="mb-1"
            >
              <strong>Emergency</strong>
              <b-row class="">
                <div class="col-4">
                  <strong>Name : </strong><span class="font-weight-bold">{{ row.item.emergency_contact_name }}</span>
                </div>
                <div class="col-4">
                  <strong>Phone : </strong><span class="font-weight-bold">{{ row.item.emergency_contact_phone }}</span>
                </div>
              </b-row>
            </b-col>
            <!--            <b-col-->
            <!--                md="12"-->
            <!--                class="mb-1"-->
            <!--            >-->
            <!--              <strong>Emergency</strong>-->
            <!--              <b-row class="px-1">-->
            <!--                <div class="col-4 mb-1">-->
            <!--                  <strong>FullName : </strong><span class="font-weight-bold">nAME</span>-->
            <!--                </div>-->
            <!--                <div class="col-4 mb-1">-->
            <!--                  <strong>Phone : </strong><span class="font-weight-bold">0233909293</span>-->
            <!--                </div>-->
            <!--              </b-row>-->
            <!--            </b-col>-->
            <div class="full-width border-bottom border-gray" />
          </b-row>
        </b-card>
      </template>
      <template #name="{ row }">
        {{ row.item.first_name }} {{ row.item.last_name }}
      </template>
      <template #status="{ row }">
        <b-badge :variant="row.item.status === 'Active' ? 'light-success' : 'light-danger'">
          <strong>{{ row.item.status.toUpperCase() }}</strong>
        </b-badge>
      </template>
      <template #completed="{ row }">
        <b-form-checkbox
          v-model="row.item.completed"
          v-b-tooltip.hover.left="row.item.completed ? 'Unchecking will simply undo the effect. All affected students will be moved back to their original classes.' : 'Checking this option will move all promoted students to the next class based on your class promotion flow wallet.'"
          :disabled="row.item.current === 0 ? false : true"
          :checked="row.item.completed ? 'true' : 'false'"
          class="custom-control-success"
          name="check-button"
          switch
          @change="completeStaffMember(row.item)"
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </template>
      <template #actions="{ row }">
        <lenon-button
          icon-only
          variant="flat-primary"
          :icon="row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
          tool-tip-text="View staff details"
          @onClick="row.toggleDetails"
        />
      </template>
    </lenon-list-page>
    <staff-position-setup
      :modal-opened="staffPositionModalOpened"
      @modalClosed="closeModal('position')"
    />
    <staff-member-setup
      :modal-opened="staffMemberModalOpened"
      @modalClosed="closeModal('member')"
    />
    <department-setup
      :modal-opened="departmentModalOpened"
      @modalClosed="closeModal('department')"
    />
    <lenon-modal
      :show="notificationModalOpened"
      :show-overlay="sendingNotification"
      title="Staff Notifications"
      :ok-text="`Send to (${selectedStaffMembers.length}) member(s)`"
      :ok-disabled="!notificationMessage || (!pushNotification && !smsNotification)"
      @onClose="closeNotificationModal()"
      @onOk="sendNotification()"
    >
      <lenon-text-area
        v-model="notificationMessage"
        rows="6"
        resize
        placeholder="Enter Message"
      />
      <b-form-group>
        <b-form-checkbox v-model="smsNotification">
          Send SMS
        </b-form-checkbox>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="pushNotification">
          Send PUSH
        </b-form-checkbox>
      </b-form-group>
    </lenon-modal>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCol, BRow, BFormCheckbox, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import StaffPositionSetup from '@/views/staff/members/StaffPositionSetup.vue'
import StaffMemberSetup from '@/views/staff/members/StaffMemberSetup.vue'
import DepartmentSetup from '@/views/staff/members/DepartmentSetup.vue'
import { STAFF_DEPARTMENTS_POSITIONS_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonTextArea from '@/lenon/components/LenonTextArea.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem.vue'
import { SEND_STAFF_NOTIFICATION_M } from '@/graphql/mutations'
import logData from '@/libs/log'

export default {
  name: 'StaffMemberList',
  components: {
    LenonTextArea,
    LenonModal,
    LenonListPage,
    DepartmentSetup,
    StaffMemberSetup,
    StaffPositionSetup,
    LenonButton,
    BCard,
    BRow,
    BCol,
    BBadge,
    BFormCheckbox,
    BFormGroup,
    LenonDropdown,
    LenonDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [showToast],
  data() {
    return {
      staffPositionModalOpened: false,
      staffMemberModalOpened: false,
      departmentModalOpened: false,
      tableLoading: false,

      notificationModalOpened: false,
      sendingNotification: false,
      smsNotification: false,
      pushNotification: false,
      notificationMessage: '',
      selectedStaffMembers: [],
    }
  },
  computed: {
    isLargeScreen() {
      return this.$store.getters['app/isLargeScreen']
    },
    smallScreen() {
      return this.$store.getters['app/smallScreen']
    },
    largeScreen() {
      return this.$store.getters['app/largeScreen']
    },
    staffMembers() {
      return this.$store.getters['staff/members']
    },
    columns() {
      const show = this.isLargeScreen
      return [
        {
          key: 'multiselect',
          label: '',
        },
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Staff Photo',
        },
        {
          key: 'name',
          label: 'Staff Full Name',
          sortable: true,
        },
        {
          key: 'staff_id',
          label: 'Staff ID',
          sortable: show,
        },
        {
          key: 'gender',
          label: 'Gender',
          sortable: show,
        },
        {
          key: 'department',
          label: 'Department',
          sortable: show,
        },
        {
          key: 'phone',
          label: 'Phone',
        },
        {
          key: 'status',
          label: 'Status',
          sortable: show,
        },
        {
          key: 'actions',
          label: 'Details',
        },
      ]
    },
  },
  mounted() {
    this.fetchStaffDepartmentsAndPositions()
  },
  methods: {
    sendNotification() {
      this.error = {}
      const notification = {
        message: this.notificationMessage,
        send_push: this.pushNotification,
        send_sms: this.smsNotification,
        staff_members: this.selectedStaffMembers.map(st => st.user_id),
      }
      this.sendingNotification = true
      this.$apollo.mutate({
        mutation: SEND_STAFF_NOTIFICATION_M,
        variables: {
          input: notification,
        },
      })
        .then(res => {
          this.showSuccess('Notifications are being sent')
          this.sendingNotification = false
          this.closeNotificationModal()
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to send notifications')
          this.sendingNotification = false
        })
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'department':
          this.departmentModalOpened = false
          break
        case 'member':
          this.staffMemberModalOpened = false
          break
        case 'position':
          this.staffPositionModalOpened = false
      }
    },
    closeNotificationModal() {
      this.notificationModalOpened = false
    },
    showNotificationModal() {
      if (this.selectedStaffMembers.length > 0) {
        this.smsNotification = false
        this.pushNotification = false
        this.notificationMessage = ''
        this.notificationModalOpened = true
      } else {
        this.showInfo('Select at least one staff member')
      }
    },
    showDepartmentModal() {
      this.departmentModalOpened = true
    },
    showStaffMemberModal() {
      this.staffMemberModalOpened = true
    },
    showStaffPositionModal() {
      this.staffPositionModalOpened = true
    },
    fetchStaffDepartmentsAndPositions() {
      this.$apollo.query({ query: STAFF_DEPARTMENTS_POSITIONS_Q })
        .then(res => {
          this.$store.commit('staff/setStaffDepartmentsAndPositions', res.data)
        })
    },
  },
}
</script>
